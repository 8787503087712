import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { Box } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";
import { ReactSVG } from "react-svg";

import IconArrowRight from "@assets/svg/arrow-right.svg";
import Link from "@components/atoms/Link";

export const Wrapper = styled(Box).withConfig({
  shouldForwardProp
})`
  position: relative;
  height: 100%;
  transition: ${({ theme }) => theme.transition};
  transition-property: transform, box-shadow;
  text-align: center;

  @media ${media.tablet} {
    &:hover {
      transform: translateY(-0.625rem);
      box-shadow: 0 0.625rem 2rem rgba(6, 34, 49, 0.25);
    }
  }
`;

export const LinkOverlay = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const Title = styled(Typography).attrs({
  variant: "body3",
  forwardedAs: "h3"
})`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Icon = styled(ReactSVG)`
  div {
    display: flex;
  }
  svg {
    font-size: 8rem;
    width: 1em;
    height: 1em;

    @media ${media.mobile} {
      font-size: 10.25rem;
    }
  }
`;

export const Arrow = styled(IconArrowRight)`
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
  fill: currentColor;
`;
