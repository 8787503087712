import React from "react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import {
  Box,
  FlexBox
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import { useTheme } from "styled-components";

import LazyHydrate from "@components/utils/LazyHydrate";

import * as Styled from "./styles";
import { ServiceCardProps } from "./types";

const ServiceCard: React.FC<ServiceCardProps> = ({
  icon,
  title,
  description,
  link: { href, rel, target },
  backgroundColor,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Styled.Wrapper
      backgroundColor={backgroundColor || theme.colors.white}
      {...rest}
    >
      <Styled.LinkOverlay {...{ href, rel, target }}>
        {title}
      </Styled.LinkOverlay>
      <FlexBox
        p={["3rem 4rem", "3rem 3rem 4rem", "1.5rem 1.5rem 3.25rem"]}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        aria-label="Service card"
      >
        <Box mb={["1rem", "2.5rem"]}>
          <LazyHydrate whenVisible>
            <Styled.Icon src={icon} />
          </LazyHydrate>
        </Box>
        <Styled.Title mb={["1rem", "2.75rem", "0.625rem"]}>
          {title}
        </Styled.Title>
        <Box display={["none", "block"]} mb={["", "1rem", "2rem"]}>
          <Typography>{description}</Typography>
        </Box>
        <FlexBox mt="auto">
          <Styled.Arrow />
        </FlexBox>
      </FlexBox>
    </Styled.Wrapper>
  );
};

export default ServiceCard;
