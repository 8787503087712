import { storyblokEditable } from "@storyblok/react";
import React from "react";

import LazyHydrate from "@components/utils/LazyHydrate";
import ServiceCard from "@components/molecules/ServiceCard";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";

import { ServiceCardStoryblokProps } from "./types";

const ServiceCardStoryblok: React.FC<ServiceCardStoryblokProps> = ({
  blok
}) => {
  const {
    icon,
    title,
    description,
    link: {
      url,
      cached_url: cachedUrl,
      anchor,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    },
    backgroundColor
  } = blok;

  const newestUrl = fullSlug || cachedUrl;

  const href = anchor ? `${newestUrl}#${anchor}` : url || newestUrl;
  const link = {
    href,
    rel,
    target
  };

  return (
    <LazyHydrate
      useDisplayContents={false}
      noWrapper
      whenVisible
      getEventTarget={() => window}
    >
      <ServiceCard
        icon={tryToReplaceDomain(icon.filename)}
        backgroundColor={backgroundColor?.color}
        {...{
          link,
          title,
          description,
          ...storyblokEditable(blok)
        }}
      />
    </LazyHydrate>
  );
};

export default ServiceCardStoryblok;
